import React, {Fragment, useEffect} from 'react'
import MainNavbar from '../../components/MainNavbar'
import Scrollbar from '../../components/scrollbar'
import PageTitle from '../../components/pagetitle'
import NarrowMessage from '../../components/NarrowMessage'
import Category from '../../components/Category'
import Central from '../../components/Central'

const VenuePage =() => {

    useEffect(() => {
        document.title = `The Wedding of Stephanie Papatsos and Michael Valeiras | Venue`;
    });

    let messageText1 = 
    "We look forward to exchanging our vows in front of our family and friends at the Mowe family residence in Leesburg, Virginia \
    and celebrating into the night under a sailcloth tent and starry sky. \
    <br><br> \
    The gracious hosts of our wedding are Roddy and Mary Mowe; Michael\'s uncle and aunt. \
    Their home sits on five gorgeous acres right next door to the farmhouse that Michael's mother, Pam, and her siblings grew up in! \
    We're so excited to start our married life right where so many happy childhood memories took place."

    let messageText2 =
    "Please remember that parking at the venue will not be permitted. \
    Shuttles will be provided for both hotels before the ceremony, after the reception, and to &amp; from the after-party.";
    

    return(
        <Fragment>
            <MainNavbar topbarNone={'topbarNone'}/>
            <PageTitle pageTitle={'Our Venue'} pagesub={'Venue'} bgClass={'wpo-page-background-tent wpo-page-background-400'} tClass={'s2'} />
            <NarrowMessage message={messageText1}/>
            <Central />
            <Category />
            <NarrowMessage message={messageText2} fClass="font-size-small font-italic pb-120 pt-60"/>
            {/* <MapBackground /> */}
            <Scrollbar/>
        </Fragment>
    )
};
export default VenuePage;
