import React from 'react'
import LocationMap from '../Modal'


const Hero7 = (props) => {
    return(
        <section className="static-hero-s3">
    <div className="hero-container">
        <div className="hero-inner">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="wpo-event-item">
                            <div className="wpo-event-text">
                                <h2>Steph & Mike</h2>
                                <p>We Are Getting Married May 20,2023</p>
                                <ul>
                                    <li>Saturday, 20 May, 2023 <br/>
                                        1:00 PM – 10:00 PM</li>
                                    <li>Fill In Here St, 525 Leesburg, VA, USA</li>
                                    <li>Phone : +15551236789</li>
                                    <li><LocationMap/></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </section>
    )
}

export default Hero7;