import React from 'react'

const NarrowMessage = (props) => {



    return(
        <section id="narrow-message">
            <div className="container-xl">
                <div className="row">
                    <div className={`narrow-message ${props.fClass}`}>
                        <div dangerouslySetInnerHTML={{__html: props.message}} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NarrowMessage;