import React from 'react'
import { Collapse } from 'bootstrap';
 
// images
import meatIcon from '../../images/rsvp/beef.png';
import chickenIcon from '../../images/rsvp/chicken.png';
import vegIcon from '../../images/rsvp/veg.png';

class ModalRsvpForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isGoing: null,
      name: "",
      submitted: false,
      failed: false
    };

    this._handleIsGoing = this._handleIsGoing.bind(this);
    this._handleName = this._handleName.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleIsGoing(event) {
    const isGoing = event.currentTarget.value === 'true' ? true: false;

    // set state
    this.setState({isGoing});
  }

  _handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const submission = this.state;

    // reset err message
    this.setState({ failed: false })

    var r = null;
    if (submission.isGoing == true) r = "1";
    if (submission.isGoing == false) r = "0";

    const i = {
      'r': r,
      'n': submission.name,
      't': this.props.rsvpType
    };
    var payload = [];
    for (var property in i) {
      if (i[property] != null) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(i[property]);
        payload.push(encodedKey + "=" + encodedValue);
      }
    }
    payload = payload.join("&");
    console.log(payload);

    fetch('https://valeiraswedding.com/api/rsvp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: payload
    })
    .then(response => {

      if (!response.ok) {
        const error = response.status;
        return Promise.reject(error);
      }

      // it worked
      this.setState({ submitted: true});
      console.log("Submission complete " + response.status);
    })
    .catch(error => {
      console.error("An error has happened.", error);
      this.setState({ failed: true});
    });
  }

  _handleName(event) {
    const name = event.target.value;
    this.setState({name});

  }

  render() {
    const {isGoing} = this.state;
    const {name} = this.state;
    const {submitted} = this.state;
    const {failed} = this.state;

    return (
      <section className={`wpo-rsvp-section ${this.props.sClass}`} id="rsvp" >
        <form onSubmit={this._handleSubmit}>
          <div className="container">
            { failed &&
              // failure box
              <div className="row">
                <div className="form-message err mb-20 col-12">
                  <p>Sorry, but something has gone wrong. Please reach out and let us know!</p>
                </div>
              </div>
            }
            { submitted &&
              // win box
              <div className="form-message win mb-20 row">
                <div className="col-12">
                  <p>Thank you for letting us know!</p>
                </div>
              </div>
            }
            { failed == false && submitted == false && 
              // info box
              <div className="form-message mb-20 row">
                <div className="col-12">
                  <p>{this.props.rsvpMessage}</p>
                </div>
              </div>
            }
            <div className={`row `}>
              <div className="d-flex justify-content-center col-12 col-lg-6">
                <label className={`radio going-button s2`}>
                  <input name="isGoing" type="radio" value="false" checked={isGoing === false} onChange={this._handleIsGoing} />
                  <span>We cannot make it.</span>
                </label>
              </div>
              <div className="d-flex justify-content-center col-12 col-lg-6">
                <label className={`radio going-button s2`}>
                  <input name="isGoing" type="radio" value="true" checked={isGoing === true} onChange={this._handleIsGoing} />
                  <span>We'll see you there!</span>
                </label>
              </div>
            </div>
            <div className="row">
              {/* <p className="instruction">We're sorry you can't make it. Please submit your name below to let us know.</p> */}
              <div className="col-12 mt-20" >
                <div className="text inline" >
                  {/* <input name="name" value={name} placeholder="Guest(s) Name" onChange={this._handleNo} /> */}
                  <label><p className="fancy">Name(s)...</p></label><input name="name" value={name} onChange={this._handleName} />
                </div>
              </div>
            </div>
            <div className="row mt-40">
              <div className='d-flex justify-content-center col-12'>
                <div className="submit slide-btns">
                  { !submitted &&
                    <a className="theme-btn-s3" rel="noopener noreferrer" onClick={this._handleSubmit} >SUBMIT</a>
                  }
                  { submitted &&
                    <a className="theme-btn-s3" rel="noopener noreferrer" onClick={this.props.handleClose} >CLOSE</a>
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    );
  }
}

export default ModalRsvpForm;