import React from 'react'
import {Link} from 'react-router-dom'
import barnClassic from '../../images/category/barn_classic-620x350.jpg'
import barnOld from '../../images/category/barn_combined-620x350.jpg'
import donkey from '../../images/category/donkey-620x350.jpg'
import grandchildren from '../../images/category/grandchildren-620x350.jpg'
import pamFarm from '../../images/category/pam_farm-620x350.jpg'
import colorFarm from '../../images/category/donkey_hat_color-620x350.jpg'

const cClass = "col-xl-4 col-lg-6 col-md-6 col-12";

const Category = (props) => {

    return(
        <section className="wpo-category-section">
            <div className="container-fluid">
                <div className="wpo-category-wrap">
                    <div className="row">
                        <div className={cClass}>
                            <div className="wpo-category-item">
                                <div className="wpo-category-img">
                                    <img src={barnClassic} alt=""/>
                                </div>
                                <div className="wpo-category-text">
                                    <Link to="#">The Farm's Barn, <i>c. 1974</i></Link>
                                </div>
                            </div>
                        </div>
                        <div className={cClass}>
                            <div className="wpo-category-item">
                                <div className="wpo-category-img">
                                    <img src={donkey} alt=""/>
                                </div>
                                <div className="wpo-category-text">
                                    <Link to="#">Eeyore the Donkey</Link>
                                </div>
                            </div>
                        </div>
                        <div className={cClass}>
                            <div className="wpo-category-item">
                                <div className="wpo-category-img">
                                    <img src={grandchildren} alt=""/>
                                </div>
                                <div className="wpo-category-text">
                                    <Link to="#">Grandchildren, <i>c. 1997</i></Link>
                                </div>
                            </div>
                        </div>
                        <div className={cClass}>
                            <div className="wpo-category-item">
                                <div className="wpo-category-img">
                                    <img src={colorFarm} alt=""/>
                                </div>
                                <div className="wpo-category-text">
                                    <Link to="#">Michael's Mom and Uncle</Link>
                                </div>
                            </div>
                        </div>
                        <div className={cClass}>
                            <div className="wpo-category-item">
                                <div className="wpo-category-img">
                                    <img src={pamFarm} alt=""/>
                                </div>
                                <div className="wpo-category-text">
                                    <Link to="#">Michael's Mom, <i>c. 1974</i></Link>
                                </div>
                            </div>
                        </div>
                        <div className={cClass}>
                            <div className="wpo-category-item">
                                <div className="wpo-category-img">
                                    <img src={barnOld} alt=""/>
                                </div>
                                <div className="wpo-category-text">
                                    <Link to="#">The Old Barn, <i>c. 2019</i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Category;