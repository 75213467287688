import React from 'react'
import MainSectionTitle from '../MainSectionTitle'
import sImg1 from '../../images/event-shape-1.png'
import sImg2 from '../../images/event-shape-2.png'
import LocationMap from '../Modal'
import ModalRsvp from '../ModalRsvp'
import NarrowMessage from '../NarrowMessage'


const EventsFriday = [
    // {
    //     Simg1: sImg1,
    //     Simg2: sImg2,
    //     title:'REHEARSAL',
    //     li1:'5:00 PM – 6:00 PM',
    //     li2:'Mowe Family Residence, 40205 Charles Town Pike, Hamilton, VA 20158',
    //     li3:'+12345678910',
    //     address: '',
    // },
    // {
    //     Simg1: sImg1,
    //     Simg2: sImg2,
    //     title:'REHEARSAL DINNER',
    //     li1:'6:00 PM – 8:30 PM',
    //     li2:'Clyde\'s Willow Creek Farm',
    //     li3:'+15712091200',
    //     address: 'Clyde\'s Willow Creek Farm, 42920 Broadlands Blvd, Broadlands, VA 20148',
    // },
    {
        Simg1: sImg1,
        Simg2: sImg2,
        title:'WELCOME DRINKS',
        li1:'8:30 PM – 10:30 PM',
        li2:'Clyde\'s Willow Creek Farm 42920 Broadlands Blvd, Broadlands, VA 20148',
        li3:'<i>Dressy casual</i>',
        address: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3099.26902584739!2d-77.518735684278!3d39.03198514660408!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b63e5804e315ef%3A0xbb5238576456ec05!2sClyde&#39;s%20Willow%20Creek%20Farm!5e0!3m2!1sen!2sus!4v1667805961746!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
        rsvp: "w",
        rsvpTitle: 'R.S.V.P. to our Welcome Drinks Event',
        rsvpMessage: `This isn't a commitment to attend, these estimates just help us plan ahead.`,
    },
]

const EventsSaturday = [
    {
        Simg1: sImg1,
        Simg2: sImg2,
        title:'CEREMONY',
        li1:'4:30 PM – 5:00 PM',
        li2: 'Mowe Family Residence<br>40205 Charles Town Pike<br>Hamilton, VA 20158',
        // li2:'Mowe Family Residence',
        // li3:'40205 Charles Town Pike, Hamilton, VA 20158',
        li4:'<i>Cocktail hour to immediately follow.</i>',
    },
    {
        Simg1: sImg1,
        Simg2: sImg2,
        title:'RECEPTION',
        li1:'6:00 PM – 11:00 PM',
        li2: 'Mowe Family Residence<br>40205 Charles Town Pike<br>Hamilton, VA 20158',
        // li2:'Mowe Family Residence',
        // li3:'40205 Charles Town Pike, Hamilton, VA 20158',
        address: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3093.952555021328!2d-77.63224158427565!3d39.15306073943775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b617609ab4738f%3A0x3a02d32db965f3d9!2s40205%20Charles%20Town%20Pike%2C%20Hamilton%2C%20VA%2020158!5e0!3m2!1sen!2sus!4v1669609211268!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
    },
    {
        Simg1: sImg1,
        Simg2: sImg2,
        title:'AFTER PARTY',
        li1:'11:00 PM – 2:00 AM',
        li2:'SideBar',
        // li3:'24 South King Street, Leesburg, VA 20175',
        li3: '<i>Shuttles provided from Reception.</i>',
        address: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3095.6413558845315!2d-77.56697738427638!3d39.11463464171393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b617d6f5552097%3A0x3b9e9d7b99ff4b5f!2sSideBar!5e0!3m2!1sen!2sus!4v1669609297462!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
        rsvp: "a",
        rsvpTitle: 'R.S.V.P. to the After Party at Sidebar',
        rsvpMessage: `This isn't a commitment to attend, these estimates just help us plan ahead.`,
    },

]

const EventsSunday = [
    {
        Simg1: sImg1,
        Simg2: sImg2,
        title:'FAREWELL LUNCHEON',
        li1:'11:30 AM - 2:30 PM',
        li2:'Lost Rhino Brewery<br>21730 Red Rum Drive #142<br>Ashburn VA 20147',
        li3:'<i>Come as you are!</i>',
        address: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3099.9630514156606!2d-77.47788008427833!3d39.016156347539564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b63ed6172195c5%3A0x40bfd5e22c48a8a8!2sLost%20Rhino%20Brewing%20Co!5e0!3m2!1sen!2sus!4v1678757607238!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
        rsvp: "b",
        rsvpTitle: 'R.S.V.P. to our Farewell Luncheon',
        rsvpMessage: `This isn't a commitment to attend, these estimates just help us plan ahead.`,
    },
]

function CreateEventItem(props) {
    return (
    <div className="wpo-event-item">
        <div className="wpo-event-text wpo-event-text-white">
            <h2 dangerouslySetInnerHTML={{__html: props.event.title}} />
            <ul>
                {props.event.li1 && 
                    <li dangerouslySetInnerHTML={{__html: props.event.li1}} />
                }
                {props.event.li2 && 
                    <li dangerouslySetInnerHTML={{__html: props.event.li2}} />
                }
                {props.event.li3 && 
                    <li dangerouslySetInnerHTML={{__html: props.event.li3}} />
                }
                {props.event.li4 && 
                    <li dangerouslySetInnerHTML={{__html: props.event.li4}} />
                }
                {props.event.address && !props.event.rsvp &&
                    <li><LocationMap title={props.event.title} address={props.event.address} /></li>
                }
                {props.event.address && props.event.rsvp &&
                    <li className="slide-btns">
                        <LocationMap title={props.event.title} address={props.event.address} buttonClass="r-10" />
                        <ModalRsvp title={props.event.rsvpTitle} rsvpType={props.event.rsvp} rsvpMessage={props.event.rsvpMessage} buttonClass="l-10" maxWidth="md" />
                    </li>
                }
            </ul>
        </div>
        <div className="event-shape-1">
            <img src={props.event.Simg1} alt="" />
        </div>
        <div className="event-shape-2">
            <img src={props.event.Simg2} alt="" />
        </div>
    </div>
    );
}

const EventSection = (props) => {

    return (
        <section className={`wpo-event-section section-padding ${props.eClass}`} id="events">
            <div className="container-lg">
                <MainSectionTitle MainTitle={'Friday'} SecondaryTitle={'May 19th, 2023'} DivId={'friday'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {EventsFriday.map((event, eitem) => (
                        <div className="col col-lg-4 col-md-12 col-12 wpo-event-center" key={eitem}>
                            <CreateEventItem event={event} />
                        </div>
                        ))}
                    </div>
                </div>
                <MainSectionTitle MainTitle={'Saturday'} SecondaryTitle={'May 20th, 2023'} DivId={'saturday'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {EventsSaturday.map((event, eitem) => (
                        <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                            <CreateEventItem event={event} />
                        </div>
                        ))}
                    </div>
                </div>
                <NarrowMessage message="The shuttles depart promptly at <b>3:30 p.m. from the Embassy Suites</b> and at <b>3:45 p.m. from Lansdowne Resort.</b>" fClass="font-size-small font-italic" />
                <MainSectionTitle MainTitle={'Sunday'} SecondaryTitle={'May 21st, 2023'} DivId={'sunday'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {EventsSunday.map((event, eitem) => (
                        <div className="col col-lg-4 col-md-6 col-12 wpo-event-center" key={eitem}>
                            <CreateEventItem event={event} />
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;