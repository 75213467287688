import React from 'react';
import AllRoute from '../router'
import '../../sass/style.scss';

const App = () => { 

  return (
    <div className="App" id='scrool'>
          <AllRoute/>
    </div>
  );
}

export default App;
