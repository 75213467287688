import React from 'react'
import MainSectionTitle from '../MainSectionTitle'
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'

// photos
import vacation_portrait from '../../images/portfolio/vacation_portrait.jpg'
import engagement from '../../images/portfolio/engagement.jpg'
import washington_monument_portrait from '../../images/portfolio/washington_monument_portrait.jpg'
import puerto_rico from '../../images/portfolio/puerto_rico.jpg'
import groomsmen from '../../images/portfolio/groomsmen.jpg'
import cairo_islanders from '../../images/portfolio/cairo_islanders_one.jpg'
import cairo_blanket from '../../images/portfolio/cairo_blanket.jpg'
import camping_pano from '../../images/portfolio/camping_pano.jpg'
import great_falls_one from '../../images/portfolio/great_falls_one.jpg'
import winery_portrait from '../../images/portfolio/winery_portrait.jpg'
import thirty from '../../images/portfolio/30th_bday.jpg'
import puerto_rico_portrait from '../../images/portfolio/puerto_rico_float_portrait.jpg'
import engagement_one from '../../images/portfolio/engagement_one.jpg'
import engagement_four from '../../images/portfolio/engagement_four.jpg'
import engagement_boat from '../../images/portfolio/engagement_boat.jpg'
import james_wedding from '../../images/portfolio/james_wedding_portrait.jpg'


import vacation_portrait_thumb from '../../images/portfolio/vacation_portrait-442x442.jpg'
import engagement_thumb from '../../images/portfolio/engagement-442x442.jpg'
import washington_monument_portrait_thumb from '../../images/portfolio/washington_monument_portrait-442x442.jpg'
import puerto_rico_thumb from '../../images/portfolio/puerto_rico-895x438.jpg'
import groomsmen_thumb from '../../images/portfolio/groomsmen-895x438.jpg'
import cairo_islanders_thumb from '../../images/portfolio/cairo_islanders_one-442x442.jpg'
import cairo_blanket_thumb from '../../images/portfolio/cairo_blanket-442x442.jpg'
import camping_pano_thumb from '../../images/portfolio/camping_pano-895x442.jpg'
import great_falls_one_thumb from '../../images/portfolio/great_falls_one-895x442.jpg'
import winery_portrait_thumb from '../../images/portfolio/winery_portrait-442x442.jpg'
import thirty_thumb from '../../images/portfolio/30th_bday-442x442.jpg'
import puerto_rico_portrait_thumb from '../../images/portfolio/puerto_rico_float_portrait-442x442.jpg'
import engagement_one_thumb from '../../images/portfolio/engagement_one-442x442.jpg'
import engagement_four_thumb from '../../images/portfolio/engagement_four-442x442.jpg'
import engagement_boat_thumb from '../../images/portfolio/engagement_boat-895x442.jpg'
import james_wedding_thumb from '../../images/portfolio/james_wedding_portrait-442x442.jpg'

const Portfolios = [
    {
        img:great_falls_one,
        thumb:great_falls_one_thumb,
        gridStyle:"big fix",
    },
    {
        img:engagement,
        thumb:engagement_thumb,
    },
    {
        img:james_wedding,
        thumb:james_wedding_thumb,
    },
    {
        img:cairo_islanders,
        thumb:cairo_islanders_thumb,
    },
    {
        img:winery_portrait,
        thumb:winery_portrait_thumb,
    },
    {
        img:camping_pano,
        thumb:camping_pano_thumb,
        gridStyle:"big",
    },
    {
        img:groomsmen,
        thumb:groomsmen_thumb,
        gridStyle:"big fix",
    },
    {
        img:engagement_one,
        thumb:engagement_one_thumb,
    },
    {
        img:thirty,
        thumb:thirty_thumb,
    },
    {
        img:puerto_rico_portrait,
        thumb:puerto_rico_portrait_thumb,
    },
    {
        img:cairo_blanket,
        thumb:cairo_blanket_thumb,
    },
    {
        img:engagement_boat,
        thumb:engagement_boat_thumb,
        gridStyle:"big",
    },
    {
        img:puerto_rico,
        thumb:puerto_rico_thumb,
        gridStyle:"big fix",
    },
    {
        img:washington_monument_portrait,
        thumb:washington_monument_portrait_thumb,
    },
    {
        img:vacation_portrait,
        thumb:vacation_portrait_thumb,
    }
] 

const PortfolioSection = (props) => {
    return(
        <section className={`wpo-portfolio-section-s2 section-padding ${props.pSclass}`} id="gallery">
            <div className="container-fluid">
                <MainSectionTitle MainTitle={'Our Favorite Moments'} SecondaryTitle={'and favorite dog!'}/>
                <div className="sortable-gallery">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-grids gallery-container clearfix">
                                {Portfolios.map((portfolio, pitem) => (
                                    <div className={`grid ${portfolio.gridStyle}`} key={pitem}>
                                        <div className="img-holder">
                                            <ReactFancyBox
                                                thumbnail={portfolio.thumb}
                                                image={portfolio.img}
                                                />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortfolioSection;