import React, { Component } from "react";
import MobileMenu from "../MobileMenu";
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import HeaderTopbar from "../HeaderTopbar";

class MainHeader extends Component {

  render() {

    const SubmitHandler = (e) => {
      e.preventDefault()
    }

    const ClickHandler = () => {
      window.scrollTo(10, 0);
    }

    const scrollWithOffset = (el) => {
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -100; 
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    let logoSnippet = null;
    if (this.props.Logo != null) {
      logoSnippet = (
        <div className="navbar-header">
          <Link onClick={ClickHandler} className="navbar-brand" to="/"><img src={this.props.Logo} alt="" /></Link>
        </div>
      );
    }

    return (
      <header id="header" className={this.props.topbarNone}>
        <HeaderTopbar/>
        <div className={`wpo-site-header ${this.props.hclass}`} id="home">
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-4 col-2 d-lg-none dl-block">
                  <div className="mobail-menu">
                    <MobileMenu />
                  </div>
                </div>
                <div className="col-lg-2 col-1 d-none d-lg-block">
                  {logoSnippet}
                </div>
                <div className="col-lg-8 col-1 d-none d-lg-block">
                  <div id="navbar" className="collapse navbar-collapse navigation-holder">
                    <button className="menu-close"><i className="ti-close"></i></button>
                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                      <li><Link onClick={ClickHandler} to="/">Home</Link></li>
                      <li><Link onClick={ClickHandler} to="/venue">Venue</Link></li>
                      <li className="menu-item-has-children">
                        <Link onClick={ClickHandler} to="/schedule">Schedule</Link>
                        <ul className="sub-menu">
                          <li><HashLink smooth to="/schedule#friday" scroll={el => scrollWithOffset(el)} >Friday</HashLink></li>
                          <li><HashLink smooth to="/schedule#saturday" scroll={el => scrollWithOffset(el)} >Saturday</HashLink></li>
                          <li><HashLink smooth to="/schedule#sunday" scroll={el => scrollWithOffset(el)} >Sunday</HashLink></li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link onClick={ClickHandler} to="/accommodations">Accommodations</Link>
                        <ul className="sub-menu">
                          <li><HashLink smooth to="/accommodations#landsdowne" scroll={el => scrollWithOffset(el)} >Landsdowne</HashLink></li>
                          <li><HashLink smooth to="/accommodations#embassysuites" scroll={el => scrollWithOffset(el)} >Embassy Suites Dulles</HashLink></li>
                        </ul>
                      </li>
                      <li><Link onClick={ClickHandler} to="/registry">Registry</Link></li>
                      <li><Link onClick={ClickHandler} to="/todo">Things to Do</Link></li>
                      <li><Link onClick={ClickHandler} to="/faq">FAQ</Link></li>
                    </ul>

                  </div>
                </div>
                <div className="col-lg-2 col-md-8 col-10">
                  <div className="header-right">
                    <div className='wide'>
                      <i>Save our Date!</i><br></br><span>MAY 20TH, 2023</span>
                    </div>
                    <div className='narrow'>
                      <i>Save our Date!</i> <span>MAY 20TH, 2023</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    )
  }
}

export default MainHeader;

