import React, {Fragment, useEffect} from 'react';
import MainNavbar from '../../components/MainNavbar'
import PageTitle from '../../components/pagetitle'
import Scrollbar from '../../components/scrollbar'
import TimeCountDown from '../../components/countdown'
import EventSection from '../../components/EventSection'
import NarrowMessage from '../../components/NarrowMessage';

let footerText =
"Because our wedding is being celebrated at a private residence, we cannot accommodate <u>any</u> onsite parking. \
Shuttles will be provided to and from the event from both The Embassy Suites and Lansdowne Resort where we are offering room blocks to our guests. \
Alternatively, you are invited to use a rideshare app to get to and from our wedding \
<br><br> \
Please reach out to Michael or Stephanie with any questions.";

const SchedulePage =() => {

    useEffect(() => {
        document.title = `The Wedding of Stephanie Papatsos and Michael Valeiras | Schedule`;
    });

    return(
        <Fragment>
            <MainNavbar  topbarNone={'topbarNone'}/>
            <PageTitle pageTitle={'Weekend Events'} pagesub={'Schedule'}  bgClass={'wpo-page-background-cubes wpo-page-background-400'} tClass={'s2'} />
            <NarrowMessage message="<h3><i>Our BIG DAY will be here before you know it...</i></h3>" />
            <TimeCountDown/>
            <EventSection eClass={'pt-0'}/>
            <NarrowMessage message={footerText} fClass="font-size-small font-italic pb-60"/>
            <Scrollbar/>
        </Fragment>
    )
};
export default SchedulePage;
