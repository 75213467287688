import React, {Fragment, useEffect} from 'react'
import MainNavbar from '../../components/MainNavbar'
import FaqItem from '../../components/FaqItem'
import Scrollbar from '../../components/scrollbar'
import Hotel1 from '../../components/Hotel1'
import PageTitle from '../../components/pagetitle'

// Images
import hotel1 from '../../images/hotel/landsdowne-aerial-1920x900.jpg'
import hotel2 from '../../images/hotel/embassy-suites-bg-1920x900.jpg'
import NarrowMessage from '../../components/NarrowMessage'

const Information = {
        title: '<h1>PLAN YOUR STAY</h1>',
        text: '<h3><br> \
            We have room blocks at two hotels in the Leesburg area: the Lansdowne Resort and the Embassy Suites - Dulles/North Loudoun. \
            You can book a room in either block using the links below. \
            </h3><br><h3> \
            Because our wedding is being celebrated at a private residence, we cannot accommodate any onsite parking. \
            Shuttles will be provided to and from the event from both the Embassy Suites and Lansdowne Resort where we are offering room blocks to our guests. \
            </h3><br><h3> \
            The shuttles depart promptly at <b>3:30 p.m. from the Embassy Suites</b> and at <b>3:45 p.m. from Lansdowne Resort.</b> \
            Please note there will only be one departure from each hotel. \
            Alternatively, you are invited to use a rideshare app to get to and from our wedding. \
            </h3>',
        underline: 'none',
    }

const Hotels = [
    {
        title:"<span>Landsdowne Resort</span>",
        anchor:"landsdowne",
        subtext:"44050 Woodridge Pkwy, Leesburg, VA 20176",
        bookingLink:"https://book.passkey.com/e/50407351",
        background: hotel1,
        bClass: 'theme-btn-s3',
    },
    {
        title:"<span>Embassy Suites Dulles</span>",
        anchor:"embassysuites",
        subtext:"44610 Waxpool Rd, Dulles, VA 20147",
        bookingLink:"https://www.hilton.com/en/book/reservation/deeplink/?ctyhocn=WASNLES&groupCode=CESPVW&arrivaldate=2023-05-18&departuredate=2023-05-21&cid=OM,WW,HILTONLINK,EN,DirectLink&fromId=HILTONLINKDIRECT",
        background: hotel2,
        sClass: "right",
        bClass: "theme-btn-s3",
    },
]

const AccommodationPage =() => {

    useEffect(() => {
        document.title = `The Wedding of Stephanie Papatsos and Michael Valeiras | Accommodations`;
    });

    return(
        <Fragment>
            <MainNavbar topbarNone={'topbarNone'}/>
            <PageTitle pageTitle={'Accommodations'} pagesub={'Accommodations'} bgClass={'wpo-page-background-map wpo-page-background-400'} tClass={'s2'}/>
            {/* <HotelHero/> */}
            <FaqItem fClass={'center-title mt-100 mb-100'} question={Information.title} answer={Information.text} />
            <Hotel1 Hotel={Hotels[0]} />
            <Hotel1 Hotel={Hotels[1]} />
            <Scrollbar/>
        </Fragment>
    )
};
export default AccommodationPage;
