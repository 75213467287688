import React from 'react'

// From SectionTnpm itleS2
const FaqItem = (props) => {


    // default
    let underlineSnippet = <div className="faq-item-img"><div className="round-ball fill"></div></div>;
    if (props.underline == "none") {
        underlineSnippet = null;
    }

    return(
        <section id="faq">
            <div className="container-lg">
                <div className="row">
                    <div className={`faq-item-s2 ${props.fClass}`}>
                        <div dangerouslySetInnerHTML={{__html: props.question}} />
                        {underlineSnippet}
                        <div dangerouslySetInnerHTML={{__html: props.answer}} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FaqItem;