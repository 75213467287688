import React from 'react'

// From SectionTitleS2
const MainSectionTitle = (props) => {
    return(

        <div className="row">
            {props.DivId &&
                <div id={props.DivId} />
            }
            <div className="wpo-section-title-s2">
                <div className="section-title-simg">
                </div>
                <h2>{props.MainTitle}</h2>
                <div className="section-title-img">
                    <div className="round-ball fill"></div>
                </div>
                <h3>{props.SecondaryTitle}</h3>
            </div>
        </div>
    )
}

export default MainSectionTitle;