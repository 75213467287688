import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../images/portfolio/engagement_top.jpg'

import top_right from '../../images/slider/shape3.png'
import bot_left from '../../images/slider/shape4.png'



class Hero3 extends Component {
    render() {

        var settings = {
            dots: true,
            arrows: false,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2500,
            fade: true
        };

        return (
            <section className="wpo-hero-slider wpo-hero-slider-s2">
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                            <div className="hero-slide">
                                <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero1})` }}>
                                    <div className="container-fluid">
                                        <div className="slide-content left">
                                            <div className="slide-title">
                                                <h2>Steph <span>&</span> Mike</h2>
                                            </div>
                                            <div className="slide-text">
                                                <p>We Are Getting Married <span>May 20th, 2023</span></p>
                                            </div>
                                            <div className="border-1"></div>
                                            <div className="border-2"></div>
                                            <div className="border-3"></div>
                                            <div className="border-4"></div>
                                            <div className="s-img-1"><img src={top_right} alt="" /></div>
                                            <div className="s-img-2"><img src={bot_left} alt="" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Hero3;