import React, {Fragment, useEffect} from 'react'
import MainNavbar from '../../components/MainNavbar'
import Scrollbar from '../../components/scrollbar'
import PageTitle from '../../components/pagetitle'
import NarrowMessage from '../../components/NarrowMessage'
import SplitSection from '../../components/SplitSection'

// images

import udvar_image from '../../images/todo/planes.jpg'
import delirium_image from '../../images/todo/delirium_image.jpg'
import stonetower_image from '../../images/todo/stonetower.jpg'
import falls_image from '../../images/todo/falls_image.jpg'
import battlefield_image from '../../images/todo/battlefield_image.jpg'
import leesburg_image from '../../images/todo/leesburg_courthouse.jpg'

const Locations = [
    {
        title: '<h2><span>UDVAR-HAZY CENTER</span></h2>',
        text: 'Michael\'s favorite museum in the DC area. An enormous hanger filled with some of the most famous air and space craft ever made!',
        buttonText: 'Visit Website',
        buttonUrl: 'https://airandspace.si.edu/visit/udvar-hazy-center',
        image: udvar_image,
        inverted: false,
        underline: true,
        sClass: "mt-100",
    },
    {
        title: '<h2><span>DELIRIUM CAFÉ</span></h2>',
        text: 'Cute café with ample outdoor seating, in the heart of downtown.',
        buttonText: 'Visit Website',
        buttonUrl: 'https://www.deliriumcafe.us/',
        image: delirium_image,
        inverted: true,
        underline: true,
    },
    {
        title: '<h2><span>HISTORIC LEESBURG</span></h2>',
        text: 'Explore historic downtown Leesburg! Enjoy the architecture, explore the shops, and eat at a local restaurant.',
        buttonText: 'Visit Website',
        buttonUrl: 'https://www.visitloudoun.org/towns-and-places/leesburg/things-to-do/',
        image: leesburg_image,
        inverted: false,
        underline: true,
    },
    {
        title: '<h2><span>STONE TOWER WINERY</span></h2>',
        text: 'One of Stephanie\'s favorite day trips! Features beautiful views, great wines, and delicious wood fire grilled pizzas.',
        buttonText: 'Visit Website',
        buttonUrl: 'https://www.stonetowerwinery.com/',
        image: stonetower_image,
        inverted: true,
        underline: true,
    },
    {
        title: '<h2><span>GREAT FALLS PARK</span></h2>',
        text: 'View the falls, then hike along the river! One of Cairo\'s favorite trails.',
        buttonText: 'Visit Website',
        buttonUrl: 'https://www.nps.gov/grfa/index.htm',
        image: falls_image,
        inverted: false,
        underline: true,
    },
    {
        title: '<h2><span>MANASSAS BATTLEFIELD</span></h2>',
        text: 'For a historic hike, visit this battleground featuring hikes of all lengths.',
        buttonText: 'Visit Website',
        buttonUrl: 'https://www.nps.gov/mana/index.htm',
        image: battlefield_image,
        inverted: true,
        underline: false,
    },
]
const TodoPage =() => {

    useEffect(() => {
        document.title = `The Wedding of Stephanie Papatsos and Michael Valeiras | Things To Do`;
    });

    let messageText = "<h3>Check out some of our favorite bites &amp; sites while you're in town!<h3>";

    return(
        <Fragment>
            <MainNavbar topbarNone={'topbarNone'}/>
            <PageTitle pageTitle={'Things to Do'} pagesub={'Things to Do'} bgClass={'wpo-page-background-falls wpo-page-background-400'} tClass={'s2'}/>
            <NarrowMessage message={messageText}/>
            {Locations.map((q, eitem) => (
                <SplitSection location={q} tClass="round" />
            ))}
            <Scrollbar/>
        </Fragment>
    )
};

export default TodoPage;
