import React, {Fragment} from 'react';
import MainNavbar from '../../components/MainNavbar';
import Hero7 from '../../components/hero7';



const InvitationPage =() => {

    return(
        <Fragment>
            <MainNavbar topbarNone={'topbarNone'}/>
            {/* <Navbar Logo={Logo} topbarNone={'topbarNone'} hclass={'wpo-header-style-2'}/> */}
            <Hero7/>
        </Fragment>
    )
};

export default InvitationPage;