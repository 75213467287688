import React from 'react'
import {Link} from 'react-router-dom'
import centralImg from '../../images/category/farm_house.jpg'


const Central = (props) => {

    return(
        <section className="wpo-central-section">
            <div className="container-fluid">
                <div className="wpo-central-wrap">
                    <div className="row wpo-central-item">
                            <img src={centralImg} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Central;