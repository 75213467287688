import React from 'react'
import {Link} from 'react-router-dom'


const SplitSection = (props) => {

        // default
        let imageSnippet = (
            <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-split-item">
                    <div className="wpo-split-img">
                        <div className="wpo-split-img-frame">
                            <img src={props.location.image} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        )

        let textSnippet = (
            <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-split-item">
                    <div className={`container slide-content wpo-split-text ${props.tClass}`}>
                        <div className ="slide-title" dangerouslySetInnerHTML={{__html: props.location.title}} />
                        <div className="slide-text">
                            <p>{props.location.text}</p>
                        </div>
                        <div className="clearfix"></div>
                        <div className="slide-btns">
                            <a href={props.location.buttonUrl} className="theme-btn-s3" target="_blank" rel="noopener noreferrer">{props.location.buttonText}</a>
                        </div>
                        <div className="site-border"></div>
                    </div>
                </div>
            </div>
        );

        let rightSnippet = null;
        let leftSnippet = null;
        if (props.location.inverted) {
            leftSnippet = textSnippet;
        }
        else {
            rightSnippet = textSnippet;
        }

        let underlineSnippet = null;
        if (props.location.underline) {
            underlineSnippet = (
            <div className="row">
                <div className="col-12">
                    <div class="wpo-underline">
                        <div class="round-ball fill"></div>
                    </div>
                </div>
            </div>
            )
        }

    return(
        <section className={`wpo-split-section ${props.location.sClass}`}>
            <div className="container-fluid">
                <div className="wpo-split-wrap">
                    <div className="row">
                        {leftSnippet}
                        {imageSnippet}
                        {rightSnippet}
                    </div>
                    {underlineSnippet}
                </div>
            </div>
        </section>
    )
}

export default SplitSection;