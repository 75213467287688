import React, {Fragment, useEffect} from 'react'
import MainNavbar from '../../components/MainNavbar'
import Scrollbar from '../../components/scrollbar'
import PageTitle from '../../components/pagetitle'
import OneSection from '../../components/OneSection'

import logo from '../../images/registry/candb.png'

const RegistryPage =() => {

    useEffect(() => {
        document.title = `The Wedding of Stephanie Papatsos and Michael Valeiras | Registry`;
    });

    let oneText = `<a href="https://www.crateandbarrel.com/gift-registry/michael-valeiras-and-stephanie-papatsos/r6685585" rel="noopener noreferrer" target="_blank">Click here to visit our registry at crateandbarrel.com</a>`;

    return(
        <Fragment>
            <MainNavbar  topbarNone={'topbarNone'}/>
            <PageTitle pageTitle={'Registry'} pagesub={'Registry'} bgClass={'wpo-page-background-gifts wpo-page-background-400'} tClass={'s2'}/>
            <OneSection logo={logo} text={oneText} sClass={'mt-100 mb-160'} tClass={'rounds s2'} pClass={'pt-20'}/>
        </Fragment>
    )
};

export default RegistryPage;
