import React, {Fragment, useEffect} from 'react';
import MainNavbar from '../../components/MainNavbar'
import PageTitle from '../../components/pagetitle'
import Scrollbar from '../../components/scrollbar'
import FaqItem from '../../components/FaqItem'

const Questions = [
    {
        question: '<h2>Are children invited to the ceremony or reception?</h2>',
        answer: '<h3>We love your kiddos! But due to the size limitations and formality of our event, we cannot accommodate children at our ceremony or reception. We hope that you\'re able to celebrate with us!</h3>',
    },
    {
        question: '<h2>What is the dress code?</h2>',
        answer: '<h3>Cocktail attire. Please note that our entire event is on the lawn, so it is recommended you wear footwear conducive to walking on grass.</h3>',
    },
    {
        question: '<h2>What is the weather like in May in Northern Virginia?</h2>',
        answer: '<h3>The average temperature in May in Northern Virginia is approximately 75°. It can be cool at night so it\'s recommended you bring a shawl or sweater.</h3>',
    },
    {
        question: '<h2>Are there hotel blocks for your event?</h2>',
        answer: '<h3>Yes! We are offering blocks of rooms at The Lansdowne Resort and The Embassy Suites (Dulles/North Loudoun). Please see the Accommodations page for more information.</h3>',
    },
    {
        question: '<h2>Is there onsite parking at your venue?</h2>',
        answer: '<h3>No. Because our wedding is being celebrated at a private residence, we cannot accommodate any onsite parking. Shuttles will be provided to and from the event from both The Embassy Suites and Lansdowne Resort where we are offering room blocks to our guests. Alternatively, you are invited to use a rideshare app to get to and from our wedding.</h3>',
    },
    {
        question: '<h2>Will transportation be provided?</h2>',
        answer: '<h3>Yes! Shuttles will be provided to and from our venue from both The Embassy Suites (Dulles/North Loudoun) and The Lansdowne Resort hotels. Check the Accommodations page for departure times!</h3>',
    },
    {
        question: '<h2>What should I check out while I\'m in town?</h2>',
        answer: '<h3>Check out our Things to Do page for suggestions on things to do nearby!</h3>',
    },
    {
        question: '<h2>What precautions are you taking against COVID-19?</h2>',
        answer: '<h3>Our entire event is taking place outside. At this time, we do not anticipate COVID-19 impacting our wedding but are asking guests to take appropriate precautions leading up to our event. If you are experiencing symptoms or have tested positive for COVID-19, we ask that you follow CDC precautions to isolate from others and do not attend the wedding. The health & safety of you and our other guests are our top priority. Please check back on this page for updated information as we get closer to the wedding!</h3>',
    },
]

const ContactPage =() => {

    useEffect(() => {
        document.title = `The Wedding of Stephanie Papatsos and Michael Valeiras | FAQ`;
    });

    return(
        <Fragment>
            <MainNavbar topbarNone={'topbarNone'}/>
            <PageTitle pageTitle={'Frequently Asked Questions'} pagesub={'F.A.Q.'} bgClass={'wpo-page-background-question wpo-page-background-400'} tClass={'s2'}/>
            {Questions.map((q, eitem) => (
                <FaqItem fClass={'mb-120 center-title'} question={q.question} answer={q.answer} />
            ))}
            <Scrollbar/>
        </Fragment>
    )
};
export default ContactPage;

