import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

// My pages
import Mainpage from '../MainPage'
import SchedulePage from '../SchedulePage'
import TodoPage from '../TodoPage'
import VenuePage from '../VenuePage';
import RegistryPage from '../RegistryPage';
import RsvpPage from '../RsvpPage';

import AccommodationPage from '../AccommodationPage'
import ErrorPage from '../ErrorPage'
import ContactPage from '../ContactPage'
import InvitationPage from '../InvitationPage'


const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Mainpage/>} />
          <Route path='/main' element={<Mainpage/>} />
          <Route path='/us' element={<Mainpage/>} />
          <Route path='/schedule' element={<SchedulePage/>} />
          <Route path='/todo' element={<TodoPage/>} />
          <Route path='/venue' element={<VenuePage/>} />
          <Route path='/registry' element={<RegistryPage/>} />
          <Route path='/invitation' element={<InvitationPage/>} />
          <Route path='/accommodations' element={<AccommodationPage/>} />
          <Route path='/404' element={<ErrorPage/>} />
          <Route path='/faq' element={<ContactPage/>} />
          <Route path='/rsvp' element={<RsvpPage/>} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
