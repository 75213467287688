import React, {Fragment, useEffect} from 'react';
import MainNavbar from '../../components/MainNavbar'
import PageTitle from '../../components/pagetitle'
import RsvpForm from '../../components/RsvpForm'



const RsvpPage =() => {

    useEffect(() => {
        document.title = `The Wedding of Stephanie Papatsos and Michael Valeiras | RSVP`;
    });

    return(
        <Fragment>
            <MainNavbar topbarNone={'topbarNone'}/>
            <PageTitle pageTitle={'R.S.V.P'}bgClass={'wpo-page-background-question wpo-page-background-400'}/>
            <RsvpForm sClass={'mt-60 mb-160'}/>
        </Fragment>
    )
};
export default RsvpPage;

