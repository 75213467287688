import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

// import hero1 from '../../images/hotel/blue-ridge-mountains-1920x900.jpg'
import hero1 from '../../images/hotel/landsdowne-aerial-1920x900.jpg'
import hero2 from '../../images/slider/slide-12.jpg'
import hero3 from '../../images/slider/slide-13.jpg'




class Hotel1 extends Component {
    render() {

        var settings = {
            dots: false,
            arrows: false,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2500,
            fade: true
        };

        return (
            <section className="wpo-hero-slider wpo-hero-slider-s3">
                <div id={this.props.Hotel.anchor} className="swiper-container">
                    <div className="swiper-wrapper">
                        <Slider {...settings}>
                            <div className="hero-slide">
                                <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${this.props.Hotel.background})` }}>
                                    <div className="container">
                                        <div className={`slide-content ${this.props.Hotel.sClass}`} >
                                            <div className="slide-title">
                                                <h2 dangerouslySetInnerHTML={{__html: this.props.Hotel.title}}></h2>
                                            </div>
                                            <div className="slide-text">
                                                <p>{this.props.Hotel.subtext}</p>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="slide-btns">
                                                <a href={this.props.Hotel.bookingLink} className={this.props.Hotel.bClass} rel="noopener noreferrer" target="_blank" >Book Here</a>
                                                {/* <Link to={this.props.Hotel.bookingLink} className="theme-btn-s3">Book Now</Link> */}
                                            </div>
                                            <div className="site-border"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
        )
    }
}

export default Hotel1;