import React from 'react'


const OneSection = (props) => {

    return(
        <section className={`wpo-one-section ${props.sClass}`}>
            <div className="container-fluid">
                <div className="wpo-one-wrap">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="wpo-one-item">
                                <div className={`container slide-content wpo-one-text ${props.tClass}`}>
                                {props.logo && 
                                    <img src={props.logo} />
                                }
                                {props.title && 
                                    <div className ="slide-title" dangerouslySetInnerHTML={{__html: props.title}} />
                                }
                                    {/* <div class="wpo-underline">
                                        <div class="round-ball fill"></div>
                                    </div> */}

                                    <div className={`slide-text ${props.pClass}`}>
                                        <p dangerouslySetInnerHTML={{__html: props.text}} />
                                    </div>
                                    <div className="site-border"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OneSection;