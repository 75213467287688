import React from "react";
import MainHeader from '../MainHeader';
import Logo from "../../images/logo-alt.png"


export default function MainNavbar(props) {
  const [scroll, setScroll] = React.useState(0);

  const handleScroll = () => setScroll(document.documentElement.scrollTop);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const className = scroll > 80 ? "fixed-navbar animated fadeInDown active" : "fixed-navbar";

  return (
    <div className={className}>
        {/* <MainHeader Logo={Logo} topbarNone={props.topbarNone} hclass={props.hclass}/> */}
        <MainHeader topbarNone={props.topbarNone} hclass={props.hclass}/>
    </div>
  ); 
}