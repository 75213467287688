import React, {Fragment, useEffect} from 'react';
import MainNavbar from '../../components/MainNavbar'
import Hero3 from '../../components/hero3';
import PortfolioSection from '../../components/PortfolioSection';
import Scrollbar from '../../components/scrollbar'



const MainPage =() => {

    useEffect(() => {
        document.title = `The Wedding of Stephanie Papatsos and Michael Valeiras`;
    });

    return(
        <Fragment>
            {/* <MainNavbar  Logo={Logo} topbarNone={'topbarNone'} hclass={'wpo-header-style-3'}/> */}
            <MainNavbar  topbarNone={'topbarNone'}/>
            <Hero3/>
            <PortfolioSection pSclass={'pt-160 pb-120'}/>
            {/* <FunFact /> */}
            <Scrollbar/>
        </Fragment>
    )
};

export default MainPage;