import React from 'react'
import { Collapse } from 'bootstrap';
 
// images
import meatIcon from '../../images/rsvp/beef.png';
import chickenIcon from '../../images/rsvp/chicken.png';
import vegIcon from '../../images/rsvp/veg.png';

class RsvpForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isGoing: null,
      numberOfGuests: 2,
      numberOfAfterParty: 2,
      no: "",
      yes: [
        {
          name: "",
          food: ""
        },
        {
          name: "",
          food: ""
        },
        {
          name: "",
          food: ""
        },
        {
          name: "",
          food: ""
        },
      ]
    };

    this._handleNumberOfAfterParty = this._handleNumberOfAfterParty.bind(this);
    this._handleNumberOfGuests = this._handleNumberOfGuests.bind(this);
    this._handleIsGoing = this._handleIsGoing.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleFood = this._handleFood.bind(this);
    this._handleYes = this._handleYes.bind(this);
    this._handleNo = this._handleNo.bind(this);
  }

  _handleNumberOfAfterParty(event) {
    const numberOfAfterParty = parseInt(event.target.value);
    const numberOfGuests = this.state.numberOfGuests;

    console.log('_handleNumberOfAfterParty', numberOfAfterParty, numberOfGuests);
    this.animateYes(this.state.isGoing, numberOfGuests);
    this.setState({numberOfAfterParty});
  }

  _handleNumberOfGuests(event) {
    const numberOfGuests = parseInt(event.target.value);
    const numberOfAfterParty = this.state.numberOfAfterParty;
    console.log('handleNumberOfGuests', numberOfGuests);
    this.animateYes(this.state.isGoing, numberOfGuests);
    this.setState({numberOfGuests});

    if (numberOfAfterParty > numberOfGuests) {
      const numberOfAfterParty = numberOfGuests;
      this.setState({numberOfAfterParty});
    }
  }

  _handleIsGoing(event) {
    const isGoing = event.currentTarget.value === 'true' ? true: false;
    console.log('handleIsGoing', isGoing);

    // animations
    var collapseNo = document.getElementById('collapse-no');
    var noObj = new Collapse(collapseNo, {toggle : false});
    isGoing ? noObj.hide() : noObj.show();

    this.animateYes(isGoing, this.state.numberOfGuests);

    var collapseAfterParty = document.getElementById('collapse-after-party');
    var afterPartyObj = new Collapse(collapseAfterParty, {toggle : false});
    isGoing ? afterPartyObj.show() : afterPartyObj.hide();

    var collapseSubmit = document.getElementById('collapse-submit');
    var submitObj = new Collapse(collapseSubmit, {toggle : false});
    isGoing == null ? submitObj.hide() : submitObj.show();


    // var collapseYes0 = document.getElementById('collapse-yes-0');
    // var collapseYes1 = document.getElementById('collapse-yes-1');
    // var collapseYes2 = document.getElementById('collapse-yes-2');
    // var collapseYes3 = document.getElementById('collapse-yes-3');
    // var yesObj0 = new Collapse(collapseYes0, {toggle : false});
    // var yesObj1 = new Collapse(collapseYes1, {toggle : false});
    // var yesObj2 = new Collapse(collapseYes2, {toggle : false});
    // var yesObj3 = new Collapse(collapseYes3, {toggle : false});
    // isGoing ? yesObj0.show() : yesObj0.hide();
    // isGoing && this.state.numberOfGuests > 1 ? yesObj1.show() : yesObj1.hide();
    // isGoing && this.state.numberOfGuests > 2 ? yesObj2.show() : yesObj2.hide();
    // isGoing && this.state.numberOfGuests > 3 ? yesObj3.show() : yesObj3.hide();

    // set state
    this.setState({isGoing});
  }

  
  _handleNo(event) {
    const no = event.target.value;
    // console.log('handleNo', no);
    this.setState({no});
  }

  _handleYes(event) {
    const n = event.target.value;
    const p = event.target.dataset.pos;
    // console.log('handleYes', p, n);

    let yes = [...this.state.yes];
    let yesItem = {...yes[p]};
    yesItem.name = n;
    yes[p] = yesItem;
    this.setState({yes});
  }

  _handleFood(event) {
    const f = event.currentTarget.value;
    const p = event.currentTarget.dataset.pos;
    console.log('handleFood', p, f);

    let yes = [...this.state.yes];
    let yesItem = {...yes[p]};
    yesItem.food = f;
    yes[p] = yesItem;
    this.setState({yes});
  }

  _handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const submission = this.state;
    console.log(submission);
  }
  
  animateYes(isGoing, numberOfGuests) {
    console.log('function call', isGoing, numberOfGuests);
    var collapseYes0 = document.getElementById('collapse-yes-0');
    var collapseYes1 = document.getElementById('collapse-yes-1');
    var collapseYes2 = document.getElementById('collapse-yes-2');
    var collapseYes3 = document.getElementById('collapse-yes-3');
    var yesObj0 = new Collapse(collapseYes0, {toggle : false});
    var yesObj1 = new Collapse(collapseYes1, {toggle : false});
    var yesObj2 = new Collapse(collapseYes2, {toggle : false});
    var yesObj3 = new Collapse(collapseYes3, {toggle : false});
    isGoing ? yesObj0.show() : yesObj0.hide();
    isGoing && numberOfGuests > 1 ? yesObj1.show() : yesObj1.hide();
    isGoing && numberOfGuests > 2 ? yesObj2.show() : yesObj2.hide();
    isGoing && numberOfGuests > 3 ? yesObj3.show() : yesObj3.hide();
  }

  render() {
    const {isGoing} = this.state;
    const {no} = this.state;
    const name0 = this.state.yes[0].name;
    const name1 = this.state.yes[1].name;
    const name2 = this.state.yes[2].name;
    const name3 = this.state.yes[3].name;
    const food0 = this.state.yes[0].food;
    const food1 = this.state.yes[1].food;
    const food2 = this.state.yes[2].food;
    const food3 = this.state.yes[3].food;

    return (
      <section className={`wpo-rsvp-section ${this.props.sClass}`} id="rsvp" >
        <form onSubmit={this._handleSubmit}>
          <div className="container">
            <div className="wpo-rsvp-form b1">
              <div className={`row ${isGoing == null ? "" : "mb-40"}`}>
                <div className="d-flex justify-content-center col-12 col-lg-6">
                  <div className={`radio going-button s1 ${isGoing == false ? "sel": "" } ${isGoing == true ? "usel": "" }`}>
                    <label>
                    <p><input name="isGoing" type="radio" value="false" checked={isGoing === false} onChange={this._handleIsGoing} />
                      We Regretfully Decline Your Invitation.<select /></p>
                    </label>
                  </div>
                </div>
                <div className="d-flex justify-content-center col-12 col-lg-6">
                <div className={`radio going-button s1 ${isGoing == true ? "sel": "" } ${isGoing == false ? "usel": "" }`}>
                    <label>
                    <p><input name="isGoing" type="radio" value="true" checked={isGoing === true} onChange={this._handleIsGoing} />
                      We Accept With Pleasure For&nbsp;
                      <select name="numberOfGuests" value={this.state.numberOfGuests} onChange={this._handleNumberOfGuests}>
                        <option value="1">&nbsp;&nbsp;1&nbsp;&nbsp;</option>
                        <option value="2">&nbsp;&nbsp;2&nbsp;&nbsp;</option>
                        <option value="3">&nbsp;&nbsp;3&nbsp;&nbsp;</option>
                        <option value="4">&nbsp;&nbsp;4&nbsp;&nbsp;</option>
                      </select>
                      &nbsp;Guests.</p>
                    </label>
                  </div>
                </div>
              </div>
              <div className="collapse" id="collapse-no">
                <div className="row">
                  <p className="instruction">We're sorry you can't make it. Please submit your name below to let us know.</p>
                  <div className="col-12" >
                    <div className="text" >
                      <input name="name" value={no} placeholder="Guest(s) Name" onChange={this._handleNo} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse" id="collapse-yes-0">
                <div className="row">
                  <p className="instruction">Please provide a name and food selection for each guest.</p>
                  <div className="col-12 col-lg-9">
                    <div className="text">
                      <input name="name0" value={name0} placeholder="Guest Name" onChange={this._handleYes} data-pos="0" />
                    </div>
                  </div>
                  <div className='col-4 col-lg-1'>
                    <div className="radio">
                      <label>
                        <input name="food0" type="radio" value="MEAT" checked={food0 === "MEAT"} onChange={this._handleFood} data-pos="0" />
                        <img className={`food-item ${food0 == "MEAT" ? "selected" : ""}`} src={meatIcon} alt="Meat"/>
                      </label>
                    </div>
                  </div>
                  <div className='col-4 col-lg-1'>
                    <div className="radio">
                      <label>
                        <input name="food0" type="radio" value="CHICKEN" checked={food0 === "CHICKEN"} onChange={this._handleFood} data-pos="0" />
                        <img className={`food-item ${food0 == "CHICKEN" ? "selected" : ""}`} src={chickenIcon} alt="Chicken"/>
                      </label>
                    </div>
                  </div>
                  <div className='col-4 col-lg-1'>
                    <div className="radio">
                      <label>
                        <input name="food0" type="radio" value="VEG" checked={food0 === "VEG"} onChange={this._handleFood} data-pos="0" />
                        <img className={`food-item ${food0 == "VEG" ? "selected" : ""}`} src={vegIcon} alt="Vegetarian"/>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse" id="collapse-yes-1">
                <div className="row">
                  <div className="col-12 col-lg-9">
                    <div className="text">
                      <input name="name1"  value={name1} placeholder="Guest Name" onChange={this._handleYes} data-pos="1" />
                    </div>
                  </div>
                  <div className='col-4 col-lg-1'>
                    <div className="radio">
                      <label>
                        <input name="food1" type="radio" value="MEAT" checked={food1 === "MEAT"} onChange={this._handleFood} data-pos="1" />
                        <img className={`food-item ${food1 == "MEAT" ? "selected" : ""}`} src={meatIcon} alt="Meat"/>
                      </label>
                    </div>
                  </div>
                  <div className='col-4 col-lg-1'>
                    <div className="radio">
                      <label>
                        <input name="food1" type="radio" value="CHICKEN" checked={food1 === "CHICKEN"} onChange={this._handleFood} data-pos="1" />
                        <img className={`food-item ${food1 == "CHICKEN" ? "selected" : ""}`} src={chickenIcon} alt="Chicken"/>
                      </label>
                    </div>
                  </div>
                  <div className='col-4 col-lg-1'>
                    <div className="radio">
                      <label>
                        <input name="food1" type="radio" value="VEG" checked={food1 === "VEG"} onChange={this._handleFood} data-pos="1" />
                        <img className={`food-item ${food1 == "VEG" ? "selected" : ""}`} src={vegIcon} alt="Vegetarian"/>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse" id="collapse-yes-2">
                <div className="row">
                  <div className="col-12 col-lg-9">
                    <div className="text">
                      <input name="name2"  value={name2} placeholder="Guest Name" onChange={this._handleYes} data-pos="2" />
                    </div>
                  </div>
                  <div className='col-4 col-lg-1'>
                    <div className="radio">
                      <label>
                        <input name="food2" type="radio" value="MEAT" checked={food2 === "MEAT"} onChange={this._handleFood} data-pos="2" />
                        <img className={`food-item ${food2 == "MEAT" ? "selected" : ""}`} src={meatIcon} alt="Meat"/>
                      </label>
                    </div>
                  </div>
                  <div className='col-4 col-lg-1'>
                    <div className="radio">
                      <label>
                        <input name="food2" type="radio" value="CHICKEN" checked={food2 === "CHICKEN"} onChange={this._handleFood} data-pos="2" />
                        <img className={`food-item ${food2 == "CHICKEN" ? "selected" : ""}`} src={chickenIcon} alt="Chicken"/>
                      </label>
                    </div>
                  </div>
                  <div className='col-4 col-lg-1'>
                    <div className="radio">
                      <label>
                        <input name="food2" type="radio" value="VEG" checked={food2 === "VEG"} onChange={this._handleFood} data-pos="2" />
                        <img className={`food-item ${food2 == "VEG" ? "selected" : ""}`} src={vegIcon} alt="Vegetarian"/>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse" id="collapse-yes-3">
                <div className="row">
                  <div className="col-12 col-lg-9">
                    <div className="text">
                      <input name="name3"  value={name3} placeholder="Guest Name" onChange={this._handleYes} data-pos="3" />
                    </div>
                  </div>
                  <div className='col-4 col-lg-1'>
                    <div className="radio">
                      <label>
                        <input name="food3" type="radio" value="MEAT" checked={food3 === "MEAT"} onChange={this._handleFood} data-pos="3" />
                        <img className={`food-item ${food3 == "MEAT" ? "selected" : ""}`} src={meatIcon} alt="Meat"/>
                      </label>
                    </div>
                  </div>
                  <div className='col-4 col-lg-1'>
                    <div className="radio">
                      <label>
                        <input name="food3" type="radio" value="CHICKEN" checked={food3 === "CHICKEN"} onChange={this._handleFood} data-pos="3"/>
                        <img className={`food-item ${food3 == "CHICKEN" ? "selected" : ""}`} src={chickenIcon} alt="Chicken"/>
                      </label>
                    </div>
                  </div>
                  <div className='col-4 col-lg-1'>
                    <div className="radio">
                      <label>
                        <input name="food3" type="radio" value="VEG" checked={food3 === "VEG"} onChange={this._handleFood} data-pos="3"/>
                        <img className={`food-item ${food3 == "VEG" ? "selected" : ""}`} src={vegIcon} alt="Vegetarian"/>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse" id="collapse-after-party">
                <div className="row">
                  <div className='col-12'>
                    <label>
                    <p>
                      And with&nbsp;
                      <select name="numberOfAfterParty" value={this.state.numberOfAfterParty} onChange={this._handleNumberOfAfterParty}>
                        <option value="1">&nbsp;&nbsp;1&nbsp;&nbsp;</option>
                        {this.state.numberOfGuests > 1 && <option value="2">&nbsp;&nbsp;2&nbsp;&nbsp;</option> }
                        {this.state.numberOfGuests > 2 && <option value="3">&nbsp;&nbsp;3&nbsp;&nbsp;</option> }
                        {this.state.numberOfGuests > 3 && <option value="4">&nbsp;&nbsp;4&nbsp;&nbsp;</option> }
                      </select>
                      &nbsp;After Party guests at Sidebar.</p>
                    </label>
                  </div>
                </div>
              </div>
              <div className="collapse" id="collapse-submit">
                <div className="row mt-40">
                  <div className='d-flex justify-content-center col-12'>
                    <div className="submit slide-btns">
                      <a className="theme-btn-s3" rel="noopener noreferrer">SUBMIT</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    );
  }
}

export default RsvpForm;